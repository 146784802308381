<template>
  <div class="home">
    <a-row style="margin-bottom: 10px;">
      <a-col :sm="24" :md="24" :xl="24" >
        <a-button type="primary" @click="showModal">
          {{ $t("permission.addRole") }}
        </a-button>
      </a-col>
    </a-row>
    <a-row>
      <a-col :span="8">
        <span style="font-weight:500;font-size:20px;padding-right:100px">{{ $t("user.role") }}</span>
        <a-list item-layout="horizontal" :data-source="roleList">
          <template #renderItem="{ item }">
            <a-list-item @click="onClickRole(item)" :style="item.Active === true ? 'background-color: rgba(0, 22, 40, 0.2)' : 'background-color:white'">
              <template #actions>
                <a @click="showModalEdit(item)">{{ $t("table.edit") }} </a>    
              </template>
              <a-list-item-meta description="">
                <template #title>
                  <a>{{ item.Name }}</a>
                </template>
                <template #avatar>
                  <a-avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
                </template>
              </a-list-item-meta>
            </a-list-item>
          </template>
        </a-list>
      </a-col>
      <a-col :span="1">
      </a-col>
      <a-col :span="12" v-if="activeRole">
        <span style="font-weight:500;font-size:20px;padding-right:10px">List of Permission for <span style="font-weight:700"> {{ activeRole.Name }} </span></span>
        <a-button type="primary" @click="saveCurrentPermissionToRole()"> {{ $t('route.save') }} </a-button>
        <a-list v-if="activeRole" size="small" item-layout="horizontal" :data-source="rma_worker_permission_list">
          <template #renderItem="{ item }">
            <a-list-item>
              <a-list-item-meta>
                <template #title>
                  <span style="padding-right:50px">
                    <input
                      type="checkbox"
                      id="checkbox"
                      :value="false"
                      v-model="item.isAssigned"
                      @change="selectPermission(item)"
                    />
                  </span>
                  {{ item.Name }}
                </template>
              </a-list-item-meta>
            </a-list-item>
          </template>
        </a-list>
      </a-col>
    </a-row>
    
    <!-- Models For Create Role -->
    <a-modal
      v-model:visible="visible"
      :title="$t('route.CreateANewRole')"
      :confirm-loading="confirmLoading"
      @ok="createWorkerSubmit()"
    >
      <a-form :model="formState" @submit="createWorkerSubmit">
        <!-- :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol" -->
        <a-form-item name="rolekey" :label="$t('route.roleid')">
          <a-input
            v-model:value="formState.Key"
            label="Role ID"
            size="large"
            type="text" 
            :placeholder="$t('route.roleid')"
          />
        </a-form-item>
        <a-form-item name="addrolename" :label="$t('route.rolename')">
          <a-input
            v-model:value="formState.Name"
            label="Role Name"
            size="large"
            type="text"
            :placeholder="$t('route.rolename')"
          />
        </a-form-item>
      </a-form>
    </a-modal>
    <!-- Models For Edit Role -->
    <a-modal
      :title="$t('route.EditRole')"
      v-model:visible="visibleEdit"
      :confirm-loading="confirmLoadingEdit"
      @ok="updateWorkersRoleSubmit"
    >
      <a-form @submit="updateWorkersRoleSubmit" :model="formState">
        <!-- :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol" -->
        <a-form-item name="editrolename" :label="$t('route.rolename')">
          <a-input
            v-model:value="formState.Name"
            label="Role Name"
            size="large"
            type="text"
            :placeholder="$t('route.rolename')"
          />
        </a-form-item>
        <a-form-item>
          <a-button danger html-type="submit" @click="deleteRole()">
            {{ $t('permission.delete') }}
          </a-button>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
  import { SearchOutlined } from '@ant-design/icons-vue';
  import { defineComponent, reactive, ref,onMounted,computed } from 'vue';
  import useRmaRepositories from "@/composables/useRmaRepositories"
  import { useI18n } from 'vue-i18n';

  // helper function to remove item form a array (base on value)
  function arrayRemove(arr, value) { 
    return arr.filter(function(ele){ 
      return ele != value; 
    });
  }

  // Main
  export default defineComponent({
    setup() {

      onMounted(async () => {
        // activeRole.value.ID = "";
        await getPermissionList()
      })

      const activeRole = ref("");
      const activeRoleID = ref("");
      const activeRolesPermission = ref();
      const currentSelectedPermission =  ref([])
      const currentDisplayPermissionList =  ref([])
      const { t ,locale } = useI18n({ useScope: 'global' })

      const selectPermission = (item) => {
        if (currentSelectedPermission.value.includes(item.ID)) {
          currentSelectedPermission.value = arrayRemove(currentSelectedPermission.value,item.ID)
        } else {
          currentSelectedPermission.value.push(item.ID)
        }
        // console.log("Current Selected Permission", currentSelectedPermission.value)
      }

      const saveCurrentPermissionToRole = async() => {
        const findRole = rma_worker_role_list.value.data.find(x => x.ID == activeRole.value.ID);
        if (findRole) {
          const response =  await updateWorkersRole({"ID": findRole.ID, "Role": currentSelectedPermission.value.toString()});
          // console.log("Current Selected Permission After save", currentSelectedPermission.value)
          await getPermissionList()
        }
      }

      // load the Role when click on sidebar
      const onClickRole = async (role) => {
        currentSelectedPermission.value = [] // reset current selected permission
        // if(activeRole.value === role){
        //   console.log("active role is current role return **")
        //   activeRole.value = ""
        //   return
        // }
        // Make current roleactive role
        activeRole.value = "";
        activeRole.value = role;
        activeRoleID.value = role.ID;
        role.Selected = true;

        // Load roles with permission 
        await getPermissionList() 
        // Find the role from Role list
        const findRole = rma_worker_role_list.value.data.find(x => x.ID == role.ID);
        // If find
        if (findRole) {
          //check this role have permission attrbute
          if(findRole.Permission){
            // permission string to a array
            const aPermissionList = findRole.Permission.split(',');
            // push current already assigned permission ids to current selected permision list 
            aPermissionList.forEach(element => {
              currentSelectedPermission.value.push(element)
            });
            rma_worker_permission_list.value.forEach(element => {
              if(aPermissionList.includes(element.ID)){
                element.isAssigned = true;
              }
            });
          }
        }
      }
      //  getPermissionList();
      const state = reactive({
        searchText: '',
        searchedColumn: '',
      });

      const searchInput = ref();
      // Load Workers List
      const {
        getAllWorkersRole,
        updateWorkersRole,
        createWorkersRole,
        deleteWorkersRole,
        rma_worker_role_list,
        getWorkersPermissionList,
        rma_worker_permission_list
      } = useRmaRepositories()
      // MODEL CREATE WORKER
      const modalText = ref('Content of the modal');
      const visible = ref(false);
      const confirmLoading = ref(false);

      const showModal = () => {
        resetFormState()
        visible.value = true;
      };

      const handleOk = async () => {
        modalText.value = 'The modal will be closed after two seconds';
        confirmLoading.value = true;
        setTimeout(() => {
          visible.value = false;
          confirmLoading.value = false;
        }, 2000);
      };

      const formState = reactive({
        ID : '',
        Key: '',
        Name:''
      });

      const resetFormState = async() => {
        formState.ID= "";
        formState.Name = "";
        formState.Key = "";
      }

      const createWorkerSubmit = async() => {
        confirmLoading.value = true;
        const response =  await createWorkersRole(formState);
        await getAllWorkersRole();
        visible.value = false;
        confirmLoading.value = false;
      }

      // MODEL FOR Edit
      const visibleEdit = ref(false);
      const confirmLoadingEdit = ref(false);

      const showModalEdit = (record) => {
        resetFormState()
        // console.log("xmax",record)
        formState.ID = record.ID;
        formState.Name = record.Name;
        formState.Key = record.Key;
        visibleEdit.value = true;
      };

      const updateWorkersRoleSubmit = async() => {
        confirmLoadingEdit.value = true;
        const response =  await updateWorkersRole(formState);
        getAllWorkersRole();
        confirmLoadingEdit.value = false;
        visibleEdit.value = false;
      }

      const deleteRole = async() =>{
        const response =  await deleteWorkersRole(formState);
        visibleEdit.value = false;
      }

      const getPermissionList = async() => {
        try {
          // get roles list with current assign permission
          await getAllWorkersRole();
          await getWorkersPermissionList();
          
          if(activeRole.value.ID){
            const findRole = rma_worker_role_list.value.data.find(x => x.ID == activeRole.value.ID);
            if(findRole){
              if(findRole.Permission){
                const currentRolesArray = findRole.Permission.split(',');
                rma_worker_permission_list.value.forEach(element => {
                  if(currentRolesArray.includes(element.ID)){
                    element.isAssigned = true;
                  }
                });
              }
            }
          }
        } catch (error) {}
      }

      const roleList = computed(() =>{
        if(activeRole.value.ID){
          rma_worker_role_list.value.data.forEach(element => {
            if(activeRole.value.ID == element.ID){
              element.Active = true;
            }
          });
        }
        return rma_worker_role_list.value.data;
      })

      return {
        modalText,
        visible,
        visibleEdit,
        confirmLoading,
        showModal,
        showModalEdit,
        handleOk,
        formState,
        createWorkerSubmit,
        updateWorkersRoleSubmit,
        deleteRole,
        rma_worker_role_list,
        getPermissionList,
        rma_worker_permission_list,
        onClickRole,
        activeRole,
        activeRolesPermission,
        selectPermission,
        currentSelectedPermission,
        saveCurrentPermissionToRole,
        roleList,
        t,
        confirmLoadingEdit
      };
    },
  });
</script>



